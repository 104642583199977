import React, { useState } from 'react'
import './contactPage.css'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import 'boxicons'

const Middle1 = () => {
  const [fname, setfName] = useState('')
  const [lname, setlName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')

  const sendContactForm = async (e) => {
    e.preventDefault()
    let ourData = {}
    // ourData.fname = fname;
    // ourData.lname = lname;
    ourData.email = email
    ourData.company = company
    ourData.message = message
    ourData.fullName = `${fname} ${lname}`

    console.log(ourData)

    let bodyData = {
      method: 'POST',
      body: JSON.stringify(ourData),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let data = await fetch(
      'https://backend.riu.ai/api/riu/postContact',
      bodyData
    )
    if (data.status === 200) {
      setfName('')
      setlName('')
      setEmail('')
      setCompany('')
      setMessage('')
    }
    console.log('result', data)
  }

  return (
    <>
      <div style={{ backgroundColor: '#f9f8f8' }}>
        <div className="title">Contact Us</div>
        <p className="para">
          If you are willing to take your business online, we have all services
          under one roof. Contact for free consultation now!
        </p>
        <Container>
          <Row>
            <Col md={12} lg={3}>
              <div className="card contact-card">
                <i className="bx bx-map logo"></i>
                <h3 className="info-address">Our Address</h3>
                {/* <p className="info-address-data">
                  Lindholmspiren 2, Göteborg, Sweden

                </p> */}
                <p className="info-address-data">
                  No. 152, 2nd Cross, MICO Layout, 2 Stage, Arekere, Bengaluru,
                  Karnataka, India – 560076
                </p>
              </div>
            </Col>
            <Col md={12} lg={3}>
              <div className="card contact-card">
                <i className="bx bx-envelope logo"></i>
                <h3 className="info-address">Email Us</h3>
                <p className="info-address-data">
                  <a className="link" href="mailto:contact@riu.ai">
                    contact@riu.ai
                  </a>
                </p>
              </div>
            </Col>
            <Col md={12} lg={3}>
              <div className="card contact-card">
                <i className="bx bx-phone-call logo"></i>
                <h3 className="info-address">Call Us</h3>
                {/* <p className="info-address-data my-2">
                  <a className="link" href="tel:+13144700001">
                    +46 - 708943293
                  </a>
                </p> */}
                <p className="info-address-data">
                  <a className="link" href="tel:+13144700001">
                    (+91) 973 – 875 – 7221
                  </a>
                </p>
              </div>
            </Col>
            <Col md={12} lg={3}>
              <div className="card contact-card">
                <i className="bx bx-user logo"></i>
                <h3 className="info-address">Follow Us</h3>
                <p className="info-address-data">
                  <a
                    href="https://www.linkedin.com/company/riuai"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bx bxl-linkedin-square social"></i>
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <div>
            <Row className="Last_T">
              <Col md={12} lg={6}>
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      title="unique"
                      className="gmap_iframe"
                      width="100%"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      // src="https://maps.google.com/maps?width=1920&amp;height=560&amp;hl=en&amp;q=Lindholmspiren 2, Göteborg, Sweden&amp;t=k&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"

                      src="https://maps.google.com/maps?width=1920&amp;height=560&amp;hl=en&amp;q=arkere main road&amp;t=k&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                  </div>
                </div>
              </Col>
              <Col md={12} lg={6} className="contact_card">
                <h2>Send Us A Message!</h2>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicFName">
                    <Form.Label name="fname" style={{ fontWeight: 'bold' }}>
                      First Name <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={fname}
                      onChange={(e) => setfName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicLName">
                    <Form.Label name="lname" style={{ fontWeight: 'bold' }}>
                      Last Name <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={lname}
                      onChange={(e) => setlName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label name="email" style={{ fontWeight: 'bold' }}>
                      Email address<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label name="company" style={{ fontWeight: 'bold' }}>
                      Company<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label name="message" style={{ fontWeight: 'bold' }}>
                      How can we help ?<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ height: '100px' }}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="danger"
                    type="submit"
                    className="button-block"
                    onClick={(e) => sendContactForm(e)}
                  >
                    SEND MESSAGE
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}
export default Middle1
